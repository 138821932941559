<template>
  <div class="page-container">
    <div class="form-container">
      <div class="page-title">供需发布</div>
      <Form ref="form" :model="model" :rules="rules" :label-width="100">
        <Row>
          <Col :span="12">
            <FormItem prop="demandName" label="标题">
              <Input v-model="model.demandName"></Input>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem prop="demandType" label="类型">
              <Select v-model="model.demandType">
                <Option value="0">供应需求</Option>
                <Option value="1">采购需求</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col :span="12">
            <FormItem prop="publisherFarmId" label="供需基地">
              <Select v-model="model.publisherFarmId">
                <Option v-for="farm in farms" :key="farm.id" :value="farm.id">
                  {{ farm.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem prop="demandAddress" label="供需地址">
              <Input v-model="model.demandAddress"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col :span="12">
            <FormItem prop="productId" label="产品">
              <Select v-model="model.productId">
                <Option
                  v-for="product in products"
                  :key="product.id"
                  :value="product.id"
                >
                  {{ product.name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <FormItem prop="demandImgs" label="图片介绍">
          <upload-image ref="upload" v-model="model.demandImgs"></upload-image>
        </FormItem>
        <Row>
          <Col :span="12">
            <FormItem prop="demandNum" label="供需数量">
              <Input v-model="model.demandNum"></Input>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem prop="demandUnit" label="数量单位">
              <Input v-model="model.demandUnit"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col :span="12">
            <FormItem prop="telName" label="联系人">
              <Input v-model="model.telName"></Input>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem prop="telPhone" label="联系电话">
              <Input v-model="model.telPhone"></Input>
            </FormItem>
          </Col>
        </Row>
        <FormItem prop="demandDesc" label="产品描述">
          <Input type="textarea" v-model="model.demandDesc"></Input>
        </FormItem>
        <FormItem prop="demandRemark" label="备注">
          <Input type="textarea" v-model="model.demandRemark"></Input>
        </FormItem>
      </Form>
      <div class="button-row">
        <span>
          <Button :loading="loading" @click="submit" type="primary">
            提交
          </Button>
        </span>
        <span>
          <Button @click="reset">重置</Button>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import CU from "@/common/util";

export default {
  data() {
    return {
      model: {
        demandType: "",
        demandName: "",
        productId: "",
        productName: "",
        demandDesc: "",
        demandRemark: "",
        demandNum: "",
        demandUnit: "",
        demandImgs: "",
        demandAddress: "",
        telPhone: "",
        telName: "",
        publisherFarmId: "",
      },
      rules: {
        demandName: [
          {
            required: true,
            message: "请填写供需标题",
          },
        ],
        demandType: [
          {
            required: true,
            message: "请选择供需类型",
          },
        ],
        publisherFarmId: [
          {
            required: true,
            message: "请选择供需基地",
          },
        ],
        demandNum: [
          {
            validator(rule, value, callback) {
              if (value === "" || value == undefined) callback();
              else if (CU.validateNumber(value)) callback();
              else callback(rule.message);
            },
            message: "请填写数字",
          },
        ],
        telPhone: [
          {
            validator(rule, value, callback) {
              if (value === "" || value == undefined) callback();
              else if (CU.validatePhone(value) || CU.validateLandline(value))
                callback();
              else callback(rule.message);
            },
            message: "请填写正确的手机号",
          },
        ],
      },
      // 产品列表
      products: [],
      // 基地列表
      farms: [],
      loading: false,
    };
  },
  watch: {
    "model.demandType": {
      handler(value) {
        if (!value) this.model.productName = "";
        else {
          let product = this.products.find((item) => item.id == value);
          if (product) {
            this.model.productName = product.name;
          } else {
            this.model.productName = "";
          }
        }
      },
    },
    "model.publisherFarmId": {
      handler(value) {
        if (!value) this.model.demandAddress = "";
        else {
          let farm = this.farms.find((item) => item.id == value);
          if (farm) {
            this.model.demandAddress = farm.address;
          } else {
            this.model.demandAddress = "";
          }
        }
      },
    },
  },
  methods: {
    // 提交
    submit() {
      this.$refs.form.validate((rs) => {
        if (!rs) return;
        this.loading = true;
        this.$post(this.$api.SUPPLY_BIND.ADD, {
          ...this.model,
        })
          .then((res) => {
            this.$Message.success("发布成功");
            this.reset();
          })
          .catch((e) => {
            this.$Message.warning("发布失败");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 重置
    reset() {
      this.$refs.form.resetFields();
      this.$refs.upload.clearFiles();
    },
    // 查询产品列表
    getProducts() {
      this.$post(this.$api.PRODUCT_INFO.LIST, {
        pageNo: 1,
        pageSize: 9999,
        catId: '8'
      })
        .then((res) => {
          this.products = res.list;
        })
        .catch((e) => {
          this.products = [];
        });
    },
    // 查询基地
    getFarms() {
      this.$post(this.$api.BASE_MANAGE.LIST)
        .then((res) => {
          this.farms = res.list;
        })
        .catch(() => {
          this.farms = [];
        });
    },
  },
  mounted() {
    this.getProducts();
    this.getFarms();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>